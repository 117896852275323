body {
  margin: 0;
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local('Roboto-Medium'), url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

input[name="AutCalHoras"] {
  text-align: right;
}